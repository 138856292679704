<template>
  <div class="detail" v-loading="loading">
    <Breadcrumb separator="->" :list="breadcrumbList"></Breadcrumb>
    <div class="detail-box">
      <div class="detail-title">{{articleData.title}}</div>
      <div class="detail-source clearfix">
        <div v-if="articleData.source" class="pull-left">来源：{{articleData.source}}</div>
        <div class="pull-left" style="padding-left: 15px;">{{articleData.publishTime}}</div>
        <div v-if="articleData.author" class="pull-right">作者：{{articleData.author}}</div>
      </div>
      <div v-html="articleData.content" style="padding: 0 20px;" class="vhtml"></div>
      <div class="file-box">
        <div v-if="articleData.attFileList">
          <video v-if="articleData.attFileList[0].type == -1" width="1000" height="500" controlslist="nodownload"
                :src="videoUrl" controls="controls"></video>
          <ul v-else>
            <li v-for="(item, index) of articleData.attFileList" :key="index" class="file-li">
              <div>
                <img :src="pdf" alt="" class="tb-icon">
                <span class="file-title" :title="item.remark">{{item.remark}}</span>
              </div>
              <div class="file-li-icon cursor" @click="fileDetail(item.path)">查看</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMaterialsDetail } from '@/api/web'

export default {
  components: {},
  name: 'MaterialDetail',
  data () {
    return {
      pdf: require('../../../assets/images/icon_pdf.png'),
      loading: false,
      breadcrumbList: [
        { name: '首页', path: '/' }
      ],
      articleData: '',
      videoUrl: '',
      videoIndex: 0
    }
  },
  created () {
    this.initialData()
  },
  mounted () {},
  methods: {
    videoItemClick (index) {
      if (this.videoIndex === index) {
        return
      }
      this.videoIndex = index
      this.videoUrl = this.articleData.attFileList[index].path
    },
    fileDetail (path) {
      window.open(path, '_blank')
    },
    initialData () {
      this.loading = true
      getMaterialsDetail({ id: this.$route.query.articleId }).then(res => {
        this.loading = false
        if (res.code === '000') {
          const data = res.result
          const arr = data.items
          if (arr[0].url) {
            this.breadcrumbList.push({ name: arr[0].name, path: `${arr[0].url}?id=${arr[0].id}` })
          } else {
            this.breadcrumbList.push({ name: arr[0].name, path: `/list?id=${arr[0].id}` })
          }
          if (arr[1]) {
            if (arr[1].url) {
              this.breadcrumbList.push({ name: arr[1].name, path: `${arr[1].url}?id=${arr[0].id}&cid=${arr[1].id}` })
            } else {
              this.breadcrumbList.push({ name: arr[1].name, path: `/list/normal?id=${arr[0].id}&cid=${arr[1].id}` })
            }
          }
          this.breadcrumbList.push({ name: '正文', path: '' })
          if (data.attFileList !== null) {
            data.attFileList.forEach(element => {
              element.type = element.path.indexOf('.pdf')
              element.path = process.env.VUE_APP_IMG_URL + element.path
            })
            this.videoUrl = data.attFileList[0].path
          }
          this.articleData = data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail{
  padding-bottom: 30px;
}
.detail-box{
  padding: 40px 80px;
  min-height: 520px;
  background-color: #ffffff;
}
.detail-title{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #E9E9E9;
}
.detail-source{
  padding-top: 10px;
  padding-bottom: 38px;
  width: 500px;
  margin: auto;
  font-size: 12px;
  color: #999999;
}
.file-box{
  padding: 20px;
}
.file-li{
  padding: 10px 65px 0 25px;
  height: 40px;
  position: relative;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 5px;
}
.file-li:hover{
  background-color: #f0f3fa;
}
.file-title{
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}
.file-li-icon{
  height: 28px;
  width: 80px;
  position: absolute;
  top: 6px;
  right: 25px;
  border-radius: 5px;
  background-color: #4E84FF;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}
.file-li-icon:hover{
  opacity: 0.8;
}
.tb-icon{
  height: 18px;
  width: 18px;
  margin-right: 10px;
}
video{
  background-color: rgba(0,0,0,.8);
  margin: 20px 0;
}
.ddd{
  min-height: 40px;
  max-height: 160px;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  margin-bottom: 25px;
}
.ddd-item{
  width: 50%;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  cursor: pointer;
}
.ddd-item-on{
  color: #4E84FF;
}
</style>
